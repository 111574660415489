// Import jQuery
import $ from 'jquery';

// Explicitly set jQuery in the global scope
window.jQuery = window.$ = $;

// Create a function that dynamically imports owl.carousel after jQuery is available globally
export const initializeOwlCarousel = async () => {
  try {
    // Import Owl Carousel dynamically to ensure jQuery is available globally first
    await import('../../../node_modules/owl.carousel/dist/owl.carousel.js');
    return true;
  } catch (error) {
    console.error('Error loading Owl Carousel:', error);
    throw error;
  }
};

// Export jQuery for consistency
export default $;
