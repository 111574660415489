import $ from 'jquery';
// Make jQuery available globally
window.$ = window.jQuery = $;

// Import the owl carousel initialization first
import { initializeOwlCarousel } from './init-owl-carousel.js';

export { $ as default };

// Enable HMR for development
if (import.meta.hot) {
  import.meta.hot.accept();
}

// Initialize any global scripts here
document.addEventListener('DOMContentLoaded', async () => {  
  try {
    // Wait for Owl Carousel to initialize
    await initializeOwlCarousel();
    
    const { initializeProjectsSlider } = await import('./projects-slider.js');
    initializeProjectsSlider();
    
    // Only import other scripts after Owl Carousel has been initialized
    await Promise.all([
      import('./accessibility-form-duplicate.js'),
      import('./contact.js'),
      import('./header.js'),
      import('./image-parallax.js'),
      import('./main.js'),
      import('./print-preparation.js'),
      import('./skipToContent.js'),
      import('./smooth-scrolling.js'),
      import('./tabs.js'),
      import('./video-play-in-view.js'),
      import('./words-animation.js')
    ]);
  } catch (error) {
    console.error('Error initializing scripts:', error);
  }
});